import { Component } from '@opten/compiler/metadata/component';

@Component({ className: 'js-video-overlay' })
export class IframeComponent {
	private id: string;

	constructor(private element: HTMLElement) {
		this.id = element.getAttribute('data-target') ?? element.id;

		this.onOverlayClick();
		const otherElement = document.querySelector(`.js-video-iframe[data-target="${this.id}"] .js-iframe-close`);
		this.onCloseClick(otherElement);
	}

	onOverlayClick() {
		this.element.addEventListener('click', () => {
			var clientHeight = this.element.clientHeight;
			this.element.classList.add('hidden');
			const otherElement = document.querySelector(`.js-video-iframe[data-target="${this.id}"]`);
			otherElement?.classList.remove('hidden');
			otherElement?.setAttribute('style', `height:${clientHeight}px;`);
			const src = otherElement?.getAttribute('data-src');
			otherElement?.querySelector('.js-iframe')?.setAttribute('src', src);
			const prev = document.getElementsByClassName('c-slider__arrow--prev');
			const next = document.getElementsByClassName('c-slider__arrow--next');
			prev[0]?.classList.add('hidden');
			next[0]?.classList.add('hidden');
		});
	}

	onCloseClick(otherElement: HTMLElement) {
		otherElement.addEventListener('click', () => {
			const parent = document.querySelector(`.js-video-iframe[data-target="${this.id}"]`);
			parent?.classList.add('hidden');
			parent?.querySelector('.js-iframe')?.setAttribute('src', '');
			this.element.classList.remove('hidden');
			const prev = document.getElementsByClassName('c-slider__arrow--prev');
			const next = document.getElementsByClassName('c-slider__arrow--next');
			prev[0]?.classList.remove('hidden');
			next[0]?.classList.remove('hidden');
		});
	}
}