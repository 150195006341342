import { Component } from '@opten/compiler/metadata/component';

declare const Cookiebot: any;
@Component({ className: 'js-cookiebot-renew' })
export class CookiebotRenewComponent {
	constructor(private element: HTMLElement) {
		this.element.addEventListener('click', e => {
			e.preventDefault();
			Cookiebot.renew();
		});
	}
}
