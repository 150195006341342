import { Component } from '@opten/compiler/metadata/component';
import Swiper, { Navigation } from 'swiper';
import { Slider, SliderOptions } from '@opten/slider';
import { SliderDotsBehavior } from '@opten/slider/dots-behavior';
import { SliderDotsResponsiveBehavior } from '@opten/slider/dots-responsive-behavior';
import { SliderArrowsResponsiveBehavior } from '@opten/slider/arrows-responsive-behavior';

import { SliderFadeBehavior } from '@opten/slider/fade-behavior';
import { SliderAutoPlayBehavior } from '@opten/slider/autoplay-behavior';
import { SliderSlidesToScrollResponsiveBehavior } from '@opten/slider/slides-to-scroll-responsive-behavior';

import { hasClass } from '@opten/dom/has-class';

Swiper.use([Navigation]);


@Component({ className: 'js-slider' })
export class SliderComponent {
    private slider: Slider;
	private swiper: Swiper;

    constructor(private element: HTMLElement) {
		this.swiper = new Swiper(element, {
			// loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
		});
		
        // const behaviors = [new SliderDotsBehavior()];
        // const options = {
        //     infinite: false,
        //     rewind: true,
		// 	rewindSpeed: 0,
        // } as SliderOptions;

        // if (hasClass(element, 'js-slider--fade')) {
        //     behaviors.push(new SliderFadeBehavior());
        //     options.ease = 'linear'
        // }
        // if (hasClass(element, 'js-slider--autoplay')) {
        //     behaviors.push(new SliderAutoPlayBehavior(5400));
        // }
        
        // this.slider = new Slider(element, options, behaviors);

    }
}

@Component({ className: 'js-slider-responsive' })
export class SliderResponsiveComponent {
    private slider: Slider;

    constructor(private element: HTMLElement) {
        const breakpoints = {
            768: 2,
            1024: 3,
            1514: 4
        };
        const behaviors = [new SliderSlidesToScrollResponsiveBehavior(breakpoints), new SliderDotsResponsiveBehavior(), new SliderArrowsResponsiveBehavior()];
        this.slider = new Slider(element, {}, behaviors);

    }
}
