import { hasClass } from '@opten/dom/has-class';
import { removeClass } from '@opten/dom/remove-class';
import { toggleClass } from '@opten/dom/toggle-class';
import { addClass } from '@opten/dom/add-class';
import { Component } from '@opten/compiler/metadata/component';

@Component({ className: 'js-nav' })
export class Navigation {
	private navToggler: Element;
	private mobileNavbar: Element;

    constructor() {
		this.navToggler = <Element>(document.getElementsByClassName('js-nav-toggler')[0]);
		this.mobileNavbar = <Element>(document.getElementById('navbar__mobile'));
		this.navToggler.addEventListener('click', () => {
			toggleClass(this.mobileNavbar, 'is-active');
			toggleClass(this.navToggler, 'is-active');
		});
    }

    private activateMenuClick() {
        
        // toggleClass(this.mainNavigationLinkElement.parentElement, 'expanded');

        // if (hasClass(this.mainNavigationLinkElement.parentElement, 'expanded')) {
        //     addClass(this.navigationContainerElement, '-invert');
        // } else {        
        //     removeClass(this.navigationContainerElement, '-invert');
        // }

        // this.mainNavigationElements
        //     .filter(mainNavigationElement => mainNavigationElement !== this.mainNavigationLinkElement.parentElement)
        //     .forEach(mainNavigationElement => removeClass(mainNavigationElement, 'expanded'));
    }
}